<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header">
        <h4>{{ type }}</h4>
      </template>
      <template slot="content">
        <div class="row info">
          <div class="col-12">
            <h6>
              Type Chirurgie 
              <span 
                class="badge" 
                :class="typeChirurgie? 'badge-warning': 'badge-danger'"
              >
                {{ typeChirurgie ? 'Petite chirurgie' : 'Chirurgie lourde' }}
              </span>
            </h6>
            <p>{{ type }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-6">
            <h6>Hospitalisation</h6>
            <p>{{ hospitalisation }}</p>
          </div>
          <div class="col-6">
            <h6>Durée hospitalisation</h6>
            <p>{{ duration }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Information supplémentaire</h6>
            <p>{{ infoSup }}</p>
          </div>
        </div>
      </template>
    </detail-sanitaire>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import navbar from '../../../components/navbar.vue'
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue'
export default {
    components: {navbar, DetailSanitaire},
    data(){
        return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Chirurgies',
                route: 'chirurgie'
              },
              {libelle: 'Detail'}
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Détail chirurgie',
            pageDescription: 'Détail de l\'opération chirurgicale',
            chirurgie: null
        }
    },
    watch: {
        chirurgies: {
            handler(){
                this.load()
            },
            deep: true
        }
    },
    mounted(){
        this.load()
    }, 
    methods: {
        load(){
            if(this.$route.params.uid !== null){
                this.chirurgie =  this.chirurgies.find(item => item.uid === this.$route.params.uid)
            }else{
                this.chirurgie =  null
            }
            
        }
    }, 
    computed: {
        ...mapGetters({
            chirurgies: 'sanitaire/chirurgies',
            types: 'sanitaire/typeChirurgies',
        }),
        hasChirurgie() { return this.chirurgie !== null && this.chirurgie !== undefined},
        animal(){
            return (this.hasChirurgie) ?  this.chirurgie.animal : null
        },
        createBy(){
            return (this.hasChirurgie)? this.chirurgie.createBy:null
        },
        createAt(){
            return (this.hasChirurgie)? this.chirurgie.createdAt:null
        },
        type(){
            if(this.hasChirurgie){
                let t = this.types.find(item => item.uid === this.chirurgie.type)
                if(t) return t.libelle
            }
            return '-'
        },
        hospitalisation(){
          return (this.hasChirurgie)?  this.chirurgie.hospitalisation : false
        },
        duration(){
          return (this.hasChirurgie && this.hospitalisation) ?   this.chirurgie.duration : 0
        },
        typeChirurgie(){
          if(this.hasChirurgie){
            let c = this.types.find(item => item.uid === this.chirurgie.type)
            if(c) return c.faible
          }
          return false
        },
        infoSup(){
            return (this.hasChirurgie)? this.chirurgie.info : '-'
        }

    }

}
</script>

<style>

</style>